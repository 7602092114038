
import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/fetchWrapper';

import { cloneDeep, isEqual } from "lodash";
import { useAssetGradesStore } from './AssetGrades';
import { useUserStore } from './user';

import { v4 as uuidv4 } from 'uuid';
import { useRoute } from 'vue-router';

import { helpers } from '@vuelidate/validators'
import { email, required, sameAs } from '@vuelidate/validators'
import moment from 'moment';
import { useAppStore } from './app';

const initialState = (uuidv4) => { return {

    'id': uuidv4,
    'created_by_id': null,
    'asset_id': null,
    'grade': null,
    'graded_at':  moment() .format(),
    'recommendations': ' ',
    'inspection_id': null,
    'answers' : [],
    'auto_complete_survey': null,
    'created_at': null,
    'updated_at': null,
    'deleted_at': null,
    }
}

export const rules = (state,route) => { return {
    'created_by_id': {},
    'asset_id': {},
    'grade': {required},
    'graded_at': {required},
    'recommendations': {},
    'inspection_id': {},
    'auto_complete_survey': {},
    'created_at': {},
    'updated_at': {},
    'deleted_at': {},
  }}

export const useAssetGradeStore = defineStore('assetGrade', {
    //As part of my persistence plugin will allow a state to be initialized after a state has been synced from storage
    state: () => ( cloneDeep(initialState(uuidv4()))),
    actions: {
        async saveAssetGrade(v$, asset_id, route_inspection_id, snackbar, loading) {
            loading.value = true
            const isFormCorrect = await v$.$validate()
            if(isFormCorrect){
                const inspection_id =  route_inspection_id
                const asset_id_save =  asset_id.value
                this.$state.asset_id = asset_id_save
                this.$state.inspection_id = inspection_id
                const assetGradesStore =  useAssetGradesStore();
                assetGradesStore.saveAssetGrade(cloneDeep(this.$state), this.router)
                this.$state = cloneDeep(initialState(uuidv4()))
                snackbar.show = true
                this.router.push({name: 'Asset-Overview', params: { asset_id: asset_id_save, inspection_id: inspection_id}})
                return
            }
            loading.value = false
        },

        async saveGradeChange(v$, asset_id) {
            const isFormCorrect = await v$.$validate()
            if(isFormCorrect){
                const user = useUserStore()

                this.$state.asset_id = asset_id
                 this.$state.created_by_id  = user.$state.user.id
                 this.$state.updated_at = moment().format()

                const assetGradesStore =  useAssetGradesStore();

                assetGradesStore.$state.assetGrades.push(cloneDeep(this.$state))
                const appStore =  useAppStore();
                appStore.setSnackbar("Grade successfully saved")

                this.$state = cloneDeep(initialState(uuidv4()))
            }
        },
        saveRepairReplaceAssetGrade(asset_id) {

            this.$state = cloneDeep(initialState(uuidv4()))
            const user = useUserStore()

            this.$state.asset_id = asset_id
            this.$state.grade = 'pass'
            this.$state.graded_at = moment().format()
            this.$state.asset_id = asset_id
            this.$state.created_by_id  = user.$state.user.id
            this.$state.updated_at = moment().format()

           const assetGradesStore =  useAssetGradesStore();
           const appStore =  useAppStore();
           appStore.setSnackbar("Successfully marked as repair/replace")
           assetGradesStore.$state.assetGrades.push(cloneDeep(this.$state))



            this.$state = cloneDeep(initialState(uuidv4()))
        },
        initViaPreviousAssetGrade(asset) {
            const assetGradesStore = useAssetGradesStore()
            const assetGradeAnswers = cloneDeep(assetGradesStore.getLatestAssetGradeWithAnswersForAssetId(asset.id.value).answers)
            assetGradeAnswers.forEach(answer => {
                answer.id = uuidv4()
                answer.value = JSON.parse(answer.value)
            });

            this.$state.answers = assetGradeAnswers
            this.$state.grade = assetGradesStore.getLatestAssetGradeWithAnswersForAssetId(asset.id.value).grade
            this.$state.grade_at = assetGradesStore.getLatestAssetGradeWithAnswersForAssetId(asset.id.value).grade_at
            this.$state.recommendations = assetGradesStore.getLatestAssetGradeWithAnswersForAssetId(asset.id.value).recommendations

        },

        initViaAssetGradeId(asset_grade_id) {
            const assetGradesStore = useAssetGradesStore()
            const assetGrade = cloneDeep(assetGradesStore.getAssetGradeById(asset_grade_id))
            this.$state = assetGrade


        },
        addRecommendedFill(text) {
            if(!this.$state.recommendations.includes('RECOMMENDED REMEDIAL WORKS'))
            {
                this.$state.recommendations += 'RECOMMENDED REMEDIAL WORKS\n';
            }
            if(!this.$state.recommendations.includes(text)){
                this.$state.recommendations += '~' + text +'\n';
            }
        },

        removeRecommendedFill(recommended_fill) {
            for (var key in recommended_fill) {
                const text = recommended_fill[key];
                const findText = '~' + text;
                const lines = this.$state.recommendations.split('\n');
                const filteredLines = lines.filter(line => line.trim() != findText.trim());
                this.$state.recommendations = filteredLines.join('\n');
            }
        }
    },
})

