<script setup>
import { defineProps, reactive, ref,computed} from 'vue'
import { storeToRefs } from 'pinia';
import moment from 'moment'
import { useRoute } from 'vue-router';
import { useAssetGradesStore } from '../stores/AssetGrades';
import { useAssetGradeStore, rules} from '../stores/AssetGrade';
import useVuelidate from '@vuelidate/core';

import DatePicker from '@/components/DatePicker.vue'
const route = useRoute()

const assetGradesStore = useAssetGradesStore()
const assetGradeStore = useAssetGradeStore()

const {grade, graded_at,recommendations} = storeToRefs(assetGradeStore);

const asset_id = route.params?.asset_id
const route_inspection_id = route.params?.inspection_id
const v$ = useVuelidate(rules(assetGradeStore.$state,route), {grade,graded_at,recommendations})
const snackbar = reactive({show: false})
const bottomSheetToggle = ref(false);
const latestAssetGradeForAssetId = computed(()=> {return assetGradesStore.getLatestAssetGradeForAssetId(asset_id)})
</script>

<template>
    <v-bottom-sheet v-model="bottomSheetToggle">
        <template v-slot:activator="{ props }">
            <v-btn-outline border="0" class="ps-3" icon="far fa-ellipsis" v-bind="props"></v-btn-outline>

        </template>
        <v-card color="#EAEAEA" >
            <v-card-text>
                <v-form fast-fail @submit.prevent="assetGradeStore.saveGradeChange(v$, asset_id)">
                    <v-row>
                        <v-col cols="12">
                            <h3>More Grading Options</h3>
                        </v-col>
                        <v-col cols="12">
                            <v-btn-outline  block prepend-icon="fas fa-clipboard-question" :to="{ name: 'Asset-Survey', params: { asset_id: asset_id}}">Start Ad Hoc Survey</v-btn-outline>
                        </v-col>
                        <v-col cols="12">
                            <v-card>
                                <v-card-text>
                                    <v-row>
                                    <v-col cols="12">
                                    <h3>Or change grade without survey:</h3>
                                    </v-col>
                                    <v-col cols="6" class="offset-3">
                                        <v-radio-group inline v-model="grade" class="inside-lable" :error-messages="v$.grade.$errors.map(e => e.$message)" @input="v$.grade.$touch" @blur="v$.grade.$touch">
                                            <v-grade-green-radio value="pass" label="Pass" class="ml-4 mr-4"></v-grade-green-radio>
                                            <v-grade-red-radio value="fail" label="Fail"></v-grade-red-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col cols="12" >
                                        <date-picker append-inner-icon="far fa-calendar" label="Date" v-model="graded_at" :error-messages="v$.graded_at.$errors.map(e => e.$message)" @input="v$.graded_at.$touch" @blur="v$.graded_at.$touch" ></date-picker>
                                    </v-col>
                                    <v-col cols="12" >
                                        <v-textarea ref="textarea"   v-model="recommendations" class="text-black"  :hint="'Leave blank if there are no recommendations. If there are no changes, and the previous recommendation still stands, please enter it again.'" label="Recommendations" :error-messages="v$.recommendations.$errors.map(e => e.$message)" @input="v$.recommendations.$touch" @blur="v$.recommendations.$touch" />
                                    </v-col>

                                    <v-col cols="12" v-if="latestAssetGradeForAssetId?.recommendations">
                                        <v-textarea :disabled="true" v-model="latestAssetGradeForAssetId.recommendations" class="text-black" :label="'Most recent recommendation:'+moment(latestAssetGradeForAssetId.graded_at).format('(DD/MM/YYYY)')" />
                                    </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-btn block type="submit" @click="bottomSheetToggle = false;" prepend-icon="fas fa-save">Save</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-bottom-sheet>
</template>
