import fuzzysort from 'fuzzysort';
import moment from 'moment';
import { flatten } from 'flat';
import { useSurveyAnswersStore } from '../stores/surveyAnswers';
import { useAssetGradesStore } from '../stores/AssetGrades';
import { useUserStore } from '../stores/user';
import { useAssetsStore } from '../stores/assets';
import { useAppStore } from '../stores/app';

export const dateStatuses = {
    "past": "Past",
    "current": "Current",
    "future": "Future"
};

export const filterState = (state) => {
    const filters = [];
    const addFilter = (fn) => {
        filters.push(fn);
        return api;
    };

    const api = {
        state,
        fuzzyFilter: (text, keys) => addFilter((state) => fuzzyFilter(state, text, keys)),
        deletedFilter: () => addFilter(deletedFilter),
        deleteNow: (state, message = '') => addFilter((state) => deleteNow(state, message)),
        dateToFilter: (status) => addFilter((state) => dateToFilter(state, status)),
        siteFilter: (site_id) => addFilter((state) => siteFilter(state, site_id)),
        sortAsc: (col) => addFilter((state) => sortAsc(state, col)),
        sortDesc: (col) => addFilter((state) => sortDesc(state, col)),
        byAssetType: (assetTypeId) => addFilter((state) => byAssetType(state, assetTypeId)),
        presentInAssetGrades: (inspectionId) => addFilter((state) => presentInAssetGrades(state, inspectionId)),
        notPresentInAssetGrades: (inspectionId) => addFilter((state) => notPresentInAssetGrades(state, inspectionId)),
        inspectionNotPresentInAssetGrades: (assetId) => addFilter((state) => inspectionNotPresentInAssetGrades(state, assetId)),
        hasAssetForSite: (assetId) => addFilter((state) => hasAssetForSite(state, assetId)),
        hasAssetTypeForAsset: (assetId) => addFilter((state) => hasAssetTypeForAsset(state, assetId)),
        where: (prop, value) => addFilter((state) => where(state, prop, value)),
        whereIn: (prop, values) => addFilter((state) => whereIn(state, prop, values)),
        byUser: () => addFilter(byUser),
        assetGradesBySiteIdFilter: (siteId) => addFilter((state) => assetGradesBySiteIdFilter(state, siteId)),
        assetGradesByAssetTypeId: (assetTypeId) => addFilter((state) => assetGradesByAssetTypeId(state, assetTypeId)),
        assetDoesNotHaveAssetGrades: () => addFilter(assetDoesNotHaveAssetGrades),
        has: (col) => addFilter((state) => has(state, col)),
        get: () => filters.reduce((s, fn) => fn(s), state),
        first: () => filters.reduce((s, fn) => fn(s), state)[0],
    };

    return api;
};

const fuzzyFilter = (state, text, keys) => {
    if (text && text !== '') {
        const results = fuzzysort.go(text, state, {
            keys: keys ?? Object.keys(flatten(state[0])),
        });
        state = results.sort((a, b) => a.score - b.score).map((result) => result.obj);
    }
    return state;
};

const deletedFilter = (state) => state.filter((result) => result.deleted_at == null);


const dateToFilter = (state, status) => state.filter((result) => {
    if (status === 'past') {
        return moment(result.date_to).diff(moment(), 'days') < 0;
    }
    if (status === 'current') {
        return moment().isBetween(moment(result.date_from), moment(result.date_to), 'days', '[]');
    }
    if (status === 'future') {
        return moment().isBefore(moment(result.date_to), 'days') && moment().isBefore(moment(result.date_from));
    }
    return false;
});

const siteFilter = (state, site_id) => site_id ? state.filter((result) => result.site_id == site_id) : state;

const sortAsc = (state, col) => col ? state.sort((a, b) => String(flatten(a)[col]).localeCompare(String(flatten(b)[col]), undefined, {
    numeric: true,
    sensitivity: 'base'
})) : state;

const sortDesc = (state, col) => col ? state.sort((a, b) => String(flatten(b)[col]).localeCompare(String(flatten(a)[col]), undefined, {
    numeric: true,
    sensitivity: 'base'
})) : state;

const byAssetType = (state, assetTypeId) => assetTypeId ? state.filter((result) => result.asset_type_id == assetTypeId) : state;

const where = (state, prop, value) => prop && value ? state.filter((result) => flatten(result)[prop] == value) : state;

const whereIn = (state, prop, values) => prop && values ? state.filter((result) => values.includes(flatten(result)[prop])) : state;

const byUser = (state) => {
    const userStore = useUserStore();
    return !userStore.isAdmin() ? state.filter((result) => result.inspectors.some(inspector => inspector.id == userStore.user_id)) : state;
};

const assetGradesBySiteIdFilter = (state, siteId) => {
    if (!siteId) return state;

    const assetsStore = useAssetsStore();
    const assetIdToSiteIdMap = new Map();

    // Prepopulate the map with asset_id to site_id mappings
    for (const asset of assetsStore.assets) {
        assetIdToSiteIdMap.set(asset.id, asset.site_id);
    }

    // Filter the state using the prepopulated map
    return state.filter((result) => assetIdToSiteIdMap.get(result.asset_id) === siteId);
};


const assetGradesByAssetTypeId = (state, assetTypeId) => {
    if (!assetTypeId) return state;

    const assetsStore = useAssetsStore();
    const assetIdToAssetTypeIdMap = new Map();

    // Prepopulate the map with asset_id to asset_type_id mappings
    for (const asset of assetsStore.assets) {
        assetIdToAssetTypeIdMap.set(asset.id, asset.asset_type_id);
    }

    // Filter the state using the prepopulated map
    return state.filter((result) => assetIdToAssetTypeIdMap.get(result.asset_id) === assetTypeId);
};

const presentInAssetGrades = (state, inspectionId) => {
    if (inspectionId) {
        const assetGradesStore = useAssetGradesStore();
        state = state.filter((result) => !!assetGradesStore.getAssetGradesByInspectionIdAndAssetId(inspectionId, result.id)?.length);
    }
    return state;
};

const notPresentInAssetGrades = (state, inspectionId) => {
    if (inspectionId) {
        const assetGradesStore = useAssetGradesStore();
        state = state.filter((result) => !assetGradesStore.getAssetGradesByInspectionIdAndAssetId(inspectionId, result.id)?.length);
    }
    return state;
};

const assetDoesNotHaveAssetGrades = (state) => {
    const assetGradesStore = useAssetGradesStore();
    const assetsWithGrades = new Set();

    // Populate the set with asset IDs that have grades
    for (const assetGrade of assetGradesStore.assetGrades) {
        assetsWithGrades.add(assetGrade.asset_id);
    }

    // Filter the state using the set
    return state.filter((result) => !assetsWithGrades.has(result.id));
};

const inspectionNotPresentInAssetGrades = (state, assetId) => {
    if (assetId) {
        const assetGradesStore = useAssetGradesStore();
        state = state.filter((result) => !assetGradesStore.getAssetGradesByInspectionIdAndAssetId(result.id, assetId)?.length);
    }
    return state;
};

const hasAssetForSite = (state, assetId) => {
    if (assetId) {
        const assetsStore = useAssetsStore();
        state = state.filter((result) => assetsStore.getAssetById(assetId)?.site_id == result.site_id && !result.deleted_at);
    }
    return state;
};

const hasAssetTypeForAsset = (state, assetId) => {
    if (assetId) {
        const assetsStore = useAssetsStore();
        state = state.filter((result) => result.asset_types.some(asset_type => asset_type.id == assetsStore.getAssetById(assetId)?.asset_type_id));
    }
    return state;
};

const has = (state, col) => col ? state.filter((result) => !!result[col]?.length) : state;

export const questionsConditionalAnswerFilter = function (questions) {


    if(questions?.length){
        const answers = useSurveyAnswersStore()
        questions = questions.filter((result) => {


            if(!result.conditional) return true

            const conditional = JSON.parse(result.conditional)
            const answer = answers.getAnswerByQuestionIdAndAnswerId(conditional.question_id, conditional.answer_type_id)
            if(answer && conditional.operator == '=='){
                if(conditional.value.includes('|'))
                {
                    const values = conditional.value.split('|')
                    let evaluation = false;
                    let answerParsed = false;
                    try {
                        answerParsed = JSON.parse(answer.value)
                    } catch(e) {
                        answerParsed = answer.value
                    }

                    for (let index = 0; index < values.length; index++) {
                        const element = values[index];
                        evaluation = element.value == answerParsed

                        if(evaluation == true) {
                            break
                        }
                    }
                    return evaluation
                }

                try {
                    return conditional.value == JSON.parse(answer.value)
                } catch(e) {
                    return conditional.value == answer.value
                }
            }
            if(answer && conditional.operator == '!='){
                try {
                    return (conditional.value != answer.value) && JSON.parse(answer.value)
                } catch(e) {
                    return (conditional.value != answer.value) && answer.value
                }
            }

            return false
        })
    }
    return questions
}
export const questionsOrder = function (questions) {
    if(questions?.length){
        questions = questions.sort((a, b) => a.order - b.order)
    }
    return questions
}

export const deleteNow = function (state, message ='') {
    state.updated_at = moment().format()
    state.deleted_at = moment().format()
    const appStore = useAppStore()
    appStore.setSnackbar("Successfully deleted " + message, function () {
        state.updated_at = moment().format()
        state.deleted_at = null
    })
    return this;
}
