
import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/fetchWrapper';
import { cloneDeep, isEqual } from "lodash";

import moment from 'moment'
import { watch } from 'vue';
import {filterState} from '../helpers/FilterWrapper';
import {useRoute} from 'vue-router';
import { stringify, v4 as uuidv4 } from 'uuid';
import { useUserStore } from './user';
import { useSurveyAnswersStore } from './surveyAnswers';
import { useFileStore } from './file';
import { useAppStore } from './app';

const initialState = {
    assetGrades: [],
}

export const useAssetGradesStore = defineStore('assetGrades', {
    persistence: true,
    state: () => ( initialState),
    getters: {
        getAssetGradeById: (state) => {
          return (assetGradeId) => {
            return state.assetGrades.find((assetGrade) => assetGrade.id === assetGradeId)
          }
        },
        getAssetGradesByInspectionId: (state) => {
          return (inpsectionId) => {
            return state.assetGrades.filter((assetGrade) => assetGrade.id === inspectionId)
          }
        },
        getAssetGradesByInspectionIdAndAssetId: (state) => {
          return (inspectionId, assetId) => {
            return state.assetGrades.filter((assetGrade) => (assetGrade.inspection_id == inspectionId) && (assetGrade.asset_id == assetId) )
          }
        },
        getAssetGradesByInspectionIdAndAssetIdAsc: (state) => {
            return (inspectionId, assetId) => {
                return filterState(state.assetGrades.filter((assetGrade) => (assetGrade.inspection_id == inspectionId) && (assetGrade.asset_id == assetId))).sortAsc('graded_at').get()
            }
        },
        getAssetGrades: (state) => {
          return (fuzzyText, dateStatus) => {
            const route = useRoute()
            return filterState(state.assetGrades).deletedFilter().dateToFilter(dateStatus).inpsectionFilter(route.params?.site_id).fuzzyFilter(fuzzyText).get();
          }
        },
        getAssetGradesBySiteId:(state) => {
            return (siteId) => {
                return filterState(state.assetGrades).deletedFilter().assetGradesBySiteIdFilter(siteId).get();
            }
        },
        getAssetGradesBySiteIdWithoutInspectionsAsc: (state) => {
            return (siteId) => {
                return filterState(state.assetGrades.filter((assetGrade) => (assetGrade.inspection_id === null)))
                    .deletedFilter()
                    .assetGradesBySiteIdFilter(siteId)
                    .sortAsc('graded_at')
                    .get()
            }
        },
        getAssetGradesByAssetTypeId:(state) => {
            return (siteId,assetTypeId) => {
                return filterState(state.assetGrades).assetGradesBySiteIdFilter(siteId).assetGradesByAssetTypeId(assetTypeId).deletedFilter().get();
            }
        },
        getLatestAssetGradeForAssetId: (state) => {
          return (assetId) => {
            return filterState(state.assetGrades).deletedFilter().where('asset_id', assetId).sortDesc('graded_at').first();
          }
        },
        getLatestAssetGradeWithAnswersForAssetId: (state) => {

          return (assetId) => {
            return filterState(state.assetGrades).deletedFilter().where('asset_id', assetId).has('answers').sortDesc('graded_at').first();
          }
        },
        getLatestAssetGradeWithAnswersForAssetIds: (state) => {

          return (assetIds) => {
            return filterState(state.assetGrades).deletedFilter().whereIn('asset_id', assetIds).has('answers').sortDesc('graded_at').get();
          }
        },
        getAssetGradesByAssetId: (state) => {
            return (assetId) => {
              return filterState(state.assetGrades).deletedFilter().where('asset_id', assetId).sortDesc('graded_at').get();
            }
        },
    },
    actions: {
        async sync() {
          await fetchWrapper.sync(`/asset-grades/index`,`/asset-grades/update-or-create` ,{
              stored: this.assetGrades.map(function (assetGrade) { return {id:assetGrade.id, updated_at: assetGrade.updated_at}}),
          }, this.assetGrades);
        },

        saveAssetGrade(assetGrade) {
            let assetGradeIndex = this.$state.assetGrades.findIndex((assetGradeToFind) => assetGradeToFind.id === assetGrade.id)
            const user = useUserStore()
            const surveryAnswers = useSurveyAnswersStore()
            const fileStore = useFileStore()
            assetGrade.created_by_id  = assetGrade.created_by_id ? assetGrade.created_by_id : user.$state.user.id
            assetGrade.updated_at = moment().format()

            surveryAnswers.$state.answers.forEach((answer) => {
                answer.asset_grade_id = assetGrade.id

                if(File.prototype.isPrototypeOf(answer.value)){
                    // fileStore.storeFile(answer.value, answer)
                }
                else {
                    try {
                        JSON.parse(answer.value);
                        answer.value = answer.value
                    }
                    catch(e) {
                        answer.value = JSON.stringify(answer.value)
                    }
                }

            })
            assetGrade.answers = surveryAnswers.$state.answers
            if(this.assetGrades[assetGradeIndex] ) {
                this.assetGrades[assetGradeIndex] = assetGrade
                const appStore =  useAppStore();
                appStore.setSnackbar("Successfully updated Asset Grade")
            } else {
                this.assetGrades.push(assetGrade)
                const appStore =  useAppStore();
                appStore.setSnackbar("Successfully created Asset Grade")
            }
        },
        clonePrevious(asset) {
            const assetGrade = cloneDeep(this.getLatestAssetGradeForAssetId(asset.id.value))
            assetGrade.id = uuidv4()
            assetGrade.graded_at = moment().format()
            assetGrade.answers.forEach(answer => {
                answer.id = uuidv4()
            });
            const appStore =  useAppStore();
            appStore.setSnackbar("Survey successfully saved")

            this.assetGrades.push(assetGrade)
        },

        latestAssetGradeForUniqueAssets(siteId, assetTypeId) {
            const assetGrades = this.getAssetGradesByAssetTypeId(siteId, assetTypeId);

            // Create a Map to track the latest asset grade for each asset ID
            const latestAssetGrades = new Map();

            assetGrades.forEach(assetGrade => {
                const existingGrade = latestAssetGrades.get(assetGrade.asset_id);
                if (!existingGrade || moment(assetGrade.graded_at).isAfter(existingGrade.graded_at)) {
                    latestAssetGrades.set(assetGrade.asset_id, assetGrade);
                }
            });

            // Convert the Map values to an array and return
            return Array.from(latestAssetGrades.values());
        },
        deleteById(id) {
            const index = this.$state.assetGrades.findIndex((asset_grade) => asset_grade.id == id)
            this.$state.assetGrades[index].updated_at = moment().format()
            this.$state.assetGrades[index].deleted_at = moment().format()
            this.$state.deep_rerender = uuidv4()
            const appStore = useAppStore()
            const vm = this
            this.router.push({name: 'Inspections'})
            appStore.setSnackbar("Successfully deleted grading",function(){

                vm.$state.assetGrades[index].updated_at = moment().format()
                vm.$state.assetGrades[index].deleted_at = null
                vm.$state.deep_rerender = uuidv4()
                vm.router.push({name: 'Inspections'})
            })

        }
    }
})
